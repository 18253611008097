import {
  AuthDto,
  LocalStoragePermissions,
  Permission3Dto,
} from '@/domain/auth/dtos/auth.dto';
import LocalStorageKey from '../constants/local-storage-key.constants';
import { getLocalStorageFn } from './localstorage.functions';
import { MENU_MODULE_ID_MAP } from '../constants/permission.constants';
import { ModuleType } from '@/core/interfaces/permission.interface';

/**
 * Chekea si es el usuario es super admin.
 */
export const imSuperAdmin = (): boolean => {
  const localStorageUserData: AuthDto = getLocalStorageFn(LocalStorageKey.USER);

  if (!localStorageUserData) return false;

  return localStorageUserData.isAdmin;
};

/**
 * Chekea si el usuario tiene un permiso.
 *
 * @param menuModuleId - Id del modulo del menu.
 * @param permission - Permiso a chekear.
 */
export const hasUserPermission = (
  menuModuleId: string,
  permission: string
): boolean => {
  const currentUserPermissions: LocalStoragePermissions =
    getLocalStorageFn(LocalStorageKey.PERMISSIONS) || {};

  const permissions = currentUserPermissions[menuModuleId] || [];
  return permissions.includes(permission);
};

/**
 * Chekea si el usuario tiene un permiso y si es super admin.
 *
 * @param permission - Permiso a chekear.
 * @param menuModuleId - Id del modulo del menu.
 */
export const validPermission = (permission: string, menuModuleId: string) =>
  imSuperAdmin() || hasUserPermission(menuModuleId, permission);

/**
 * Obtiene el id del menu por el nombre clave del modulo.
 *
 * @param moduleName - Nombre clave del modulo.
 * @returns Id del menu.
 */
export const getMenuIdByModuleName = (moduleName: ModuleType) =>
  MENU_MODULE_ID_MAP[moduleName];

/**
 * Formatea recursivamente los permisos de la respuesta del login del backend.
 *
 * @param data - Datos de los permisos.
 * @param formattedPermission - Permisos formateados.
 */
export const formatPermissionsRecursively = (
  data: Permission3Dto[],
  formattedPermission: LocalStoragePermissions = {}
) => {
  for (const menuItem of data) {
    if (!menuItem.menuChilds?.length) {
      formattedPermission[menuItem.idMenu] = menuItem.access.map(
        (access) => access.actionCode
      );
    } else {
      formatPermissionsRecursively(menuItem.menuChilds, formattedPermission);
    }
  }
  return formattedPermission;
};
